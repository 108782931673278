<template>
  <div>
    <section>
      <div>
        <img
          style="width: 100%; height: 100%"
          src="@/assets/images/desktop/process/Vector.png"
          alt="灰底"
        />

        <div class="gray-bk">
          <div
            style="
              position: absolute;
              transform: translateX(-50%);
              left: 50%;
              top: 0px;
            "
          >
            <div
              style="display: flex; padding-top: 66px; justify-content: center"
            >
              <img src="@/assets/images/desktop/process/right.svg" alt="" />
              <p class="step-title1">代買流程</p>
              <img src="@/assets/images/desktop/process/left.svg" alt="" />
            </div>

            <div style="display: flex; justify-content: center">
              <div class="box">
                <div class="step-number-photo">
                  <div class="Step-number">
                    <p>Step.1</p>
                    <p>使用</p>
                  </div>
                  <img
                    src="@/assets/images/desktop/process/use.svg"
                    alt="使用"
                  />
                </div>
                <p>進入Messenger，點擊開始，開啟代買服務。</p>
              </div>

              <div class="arrow-purchase"></div>

              <div class="box">
                <div class="step-number-photo">
                  <div class="Step-number">
                    <p>Step.2</p>
                    <p>委託</p>
                  </div>
                  <img
                    src="@/assets/images/desktop/process/Group 3830.svg"
                    alt="委託"
                  />
                </div>
                <p>填寫委託單，貼上商品連結，填寫好收件資訊，並送出申請。</p>
              </div>

              <div class="arrow-purchase"></div>

              <div class="box">
                <div class="step-number-photo">
                  <div class="Step-number">
                    <p>Step.3</p>
                    <p>報價</p>
                  </div>
                  <img
                    src="@/assets/images/desktop/process/Group 3868.svg"
                    alt="報價"
                  />
                </div>
                <p>收到委託單，將在兩個工作天內完成估價。</p>
              </div>

              <img
                class="position-absolute"
                style="top: 38%; right: -28px"
                src="@/assets/images/desktop/process/Group 3872.svg"
                alt=""
              />
            </div>

            <div class="down" style="display: flex; justify-content: center">
              <div class="box">
                <div class="step-number-photo">
                  <div class="Step-number">
                    <p>Step.6</p>
                    <p>取貨</p>
                  </div>
                  <img
                    src="@/assets/images/desktop/process/Group 3869.svg"
                    alt="取貨"
                  />
                </div>
                <p>
                  開箱時，別忘記拍照或錄影，後續有爭議時，以利向1688
                  廠商溝通唷！
                </p>
              </div>
              <div class="arrow-purchase"></div>

              <div class="box">
                <div class="step-number-photo">
                  <div class="Step-number">
                    <p>Step.5</p>
                    <p>寄送</p>
                  </div>
                  <img
                    src="@/assets/images/desktop/process/Group 3870.svg"
                    alt="寄送"
                  />
                </div>
                <p>
                  1688 廠商會進行出貨，同時，也請您事先做好EZ
                  WAY易利委申報，以利加快商品通關。
                </p>
              </div>
              <div class="arrow-purchase"></div>

              <div class="box">
                <div class="step-number-photo">
                  <div class="Step-number">
                    <p>Step.4</p>
                    <p>付款</p>
                  </div>
                  <img
                    src="@/assets/images/desktop/process/Group 3871.svg"
                    alt="付款"
                  />
                </div>
                <p>我們會提供您匯款資訊，請於 24 小時內完成付款。</p>
              </div>
            </div>
          </div>
        </div>
        <img
          style="width: 100%; height: 100%; margin-top: -2px"
          src="@/assets/images/desktop/process/Vector2.png"
          alt="灰底"
        />
      </div>
    </section>

    <!-- 4個人 -->
    <section>
      <div style="margin-top: 80px; position: relative">
        <p class="process-content">
          只要想在1688上購物 <br />
          都歡迎使用 <span style="color: #3c5bff">CHATxBUY</span>
        </p>

        <div
          style="
            display: flex;
            justify-content: center;
            margin-top: 50px;
            margin-bottom: 47px;
            position: relative;
            z-index: 2;
          "
        >
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img
              class=""
              src="@/assets/images/desktop/process/people1.svg"
              alt="個人買家"
            />
            <p class="people-content">個人買家</p>
          </div>

          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img
              class=""
              src="@/assets/images/desktop/process/people2.svg"
              alt="1688批貨小白"
            />
            <p class="people-content">1688批貨小白</p>
          </div>

          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img
              class=""
              src="@/assets/images/desktop/process/people3.svg"
              alt="1688批貨老鳥"
            />
            <p class="people-content">1688批貨老鳥</p>
          </div>

          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img
              class=""
              src="@/assets/images/desktop/process/people4.svg"
              alt="小資商家"
            />
            <p class="people-content">小資商家</p>
          </div>
        </div>
        <div class="wh-ellipse"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    goChatbot() {
      gtag("event", "Index_click", {
        action: "goChatbot",
        page: "process",
      });
      if (localStorage.utm_ref != null) {
        window.open("https://m.me/chatXbuy?ref=" + localStorage.utm_ref);
      } else {
        window.open("https://m.me/chatXbuy");
      }
    },
  },
  mounted() {
    console.log(this.$route);
    document.dispatchEvent(new Event('render-event')); //prerender
  },
};
</script>

<style scope>
.gray-bk {
  background-color: #f1f3fa;
  width: 100%;
  height: 787px;
  position: relative;
}

.step-title1 {
  font-weight: 800;
  font-size: 38px;
  transform: scaleY(1.05);
  color: #343a40;
  margin: 0;
  padding-left: 22px;
  padding-right: 22px;
}

/* 4個人 */
.process-content {
  font-size: 32px;
  color: #343a40;
  height: auto;
  text-align: center;
  font-weight: 500;
}

.people-content {
  font-size: 20px;
  color: #212529;
}

.people-and-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 53px;
}

/* 代買流程 */
.box {
  border: 1px solid #ced6ff;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  max-width: 250px;
  box-shadow: 0 0.5px 30px 0 rgba(241, 238, 238, 0.496);
  margin: 3rem 1.5rem;
  min-width: 242px;
  min-height: 200px;
}

.step:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -30px;
  border: 15px solid transparent;
  border-left-color: #ccc;
}

.step-icon {
  background-color: #007bff;
  color: white;
  display: inline-block;
  padding: 10px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.top {
  display: flex;
  justify-content: center;
}

.Step-number > p:nth-child(1) {
  font-weight: 600;
  font-size: 16px;
  color: #2aa5ff;
}

.Step-number > p:nth-child(2) {
  font-weight: 600;
  font-size: 24px;
  color: #060e57;
  margin-top: -1rem;
}

.step-number-photo {
  display: flex;
  justify-content: space-between;
}

.step-number-photo > img {
  margin-top: -20px;
}

.box > p {
  font-size: 15px;
  color: #495057;
}

/* 箭頭 */
.arrow-purchase {
  position: relative;
  width: 60px;
  height: 18px;
  background-color: #ced6ff;
  margin-top: 150px;
  right: 24px;
}

.arrow-purchase::after {
  content: "";
  position: absolute;
  top: -11px;
  right: -30px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 38px solid #ced6ff;
  border-color: transparent transparent transparent #ced6ff;
}

/* 創建箭頭形狀 */
.top > .arrow-purchase::after {
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}

.down > .arrow-purchase {
  position: relative;
  width: 60px;
  height: 18px;
  background-color: #ced6ff;
  margin-top: 150px;
  right: -24px;
}

.down > .arrow-purchase::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  background: #ced6ff;
}

/* 創建箭頭形狀 */
.down > .arrow-purchase::after {
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
}

.wh-ellipse {
  width: 100%;
  height: 190px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 340px;
  z-index: 1;
}
</style>
