<template>
  <div class="gradient-layer">
    <img
      class="transparent-bg"
      src="@/assets/images/desktop/hero/Vector.svg"
      alt="代買幫"
    />
    <img
      class="transparent-bg2"
      src="@/assets/images/desktop/hero/image 12.svg"
      alt="代買幫"
    />
    <img
      class="key-visual"
      src="@/assets/images/desktop/hero/Group 3859.svg"
      alt="代買幫"
    />

    <div>
      <div class="hero-title">代買幫 CHATxBUY</div>
      <div class="hero-title2">專業阿里巴巴 1688 代買</div>
      <div class="hero-content">
        從下單、賣家溝通、國際集運到商品抵達，代買幫都幫您<br />
        一手包辦。讓您可以享受無憂的 1688 代買體驗！
      </div>
    </div>

    <img
      class="transparent-bg3"
      @click="goChatbot"
      src="@/assets/images/desktop/hero/Frame 624684.png"
      alt="代買幫"
    />
    <img
      class="transparent-down"
      src="@/assets/images/desktop/hero/Group.svg"
      alt="下圓弧"
    />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    goChatbot() {
      gtag("event", "Index_click", { action: "goChatbot", page: "hero" });
      if (localStorage.utm_ref != null) {
        window.open("https://m.me/chatXbuy?ref=" + localStorage.utm_ref);
      } else {
        window.open("https://m.me/chatXbuy");
      }
    },
  },
  mounted() {
    console.log(this.$route);
  },
};
</script>
<style scope>
.gradient-layer {
  background: linear-gradient(to bottom right, #91acf9, #3c5bff);
  width: 100%;
  height: 42.474vw;
  position: relative;
}

.transparent-bg {
  position: absolute;
  right: 0px;
  top: 5%;
  width: 60vw;
}

.transparent-bg2 {
  width: 10vw;
  height: auto;
  position: absolute;
  left: 5%;
  top: 10%;
}

.key-visual {
  width: 53vw;
  height: auto;
  position: absolute;
  left: 44%;
  top: 8%;
}

.transparent-down {
  width: 100%;
  height: auto;
  position: absolute;
  top: 83%;
}

.hero-title {
  font-weight: bold;
  font-size: 3vw;
  position: absolute;
  top: 9.5vw;
  left: 11.5vw;
  transform: scaleY(1.07);
}

.hero-title2 {
  font-weight: bold;
  color: #ffffff;
  font-size: 2.1vw;
  height: auto;
  position: absolute;
  top: 14.5vw;
  left: 11.5vw;
}

.hero-content {
  font-weight: Medium;
  color: #ffffff;
  font-size: 1.3vw;
  height: auto;
  position: absolute;
  top: 19vw;
  left: 11.5vw;
  line-height: 2.2vw;
}

.transparent-bg3 {
  width: 18vw;
  height: auto;
  position: absolute;
  left: 11.5vw;
  top: 27vw;
  cursor: pointer;
}

.transparent-bottom {
  height: auto;
  position: absolute;
  top: 36vw;
}
</style>
