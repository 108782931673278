<script setup>
import NumberAnimation from "vue-number-animation";
</script>

<template>
  <div>
    <section style="position: relative">
      <div class="blue-background">
        <div style="display: flex; padding-top: 151px; justify-content: center">
          <img
            style=""
            src="@/assets/images/desktop/products/right.svg"
            alt=""
          />
          <p class="service-results" style="">服務成果</p>
          <img
            style=""
            src="@/assets/images/desktop/products/left.svg"
            alt=""
          />
        </div>

        <!-- 數字自己跑  -->
        <div class="count-content">
          <div class="count-window">
            <div
              style="
                display: flex;
                width: 1120px;
                justify-content: space-around;
                align-items: center;
              "
            >
              <div
                style="
                  margin-left: 10px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <NumberAnimation
                  v-observe-visibility="visibilityChanged"
                  class="count-number"
                  ref="number1"
                  :from="3000000"
                  :to="5000000 - randomInRange(10000)"
                  :format="(v) => v.toFixed(0)"
                  :duration="0.5"
                  :delay="1"
                  easing="linear"
                />
                <div class="count-text">累積出貨訂單</div>
              </div>

              <div class="line"></div>

              <div style="display: flex; flex-direction: row">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <div style="display: flex; flex-direction: row">
                    <NumberAnimation
                      v-observe-visibility="visibilityChanged"
                      class="count-number"
                      ref="number2"
                      :from="85.5"
                      :to="99.9 - randomInRange(10)"
                      :format="theFormat"
                      :duration="0.5"
                      :delay="1"
                      easing="linear"
                    />
                    <p class="count-percent">%</p>
                  </div>
                  <div class="count-text-percent">客戶回覆率</div>
                </div>
              </div>
              <div class="line"></div>

              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <div style="display: flex; flex-direction: row">
                  <NumberAnimation
                    v-observe-visibility="visibilityChanged"
                    class="count-number"
                    ref="number3"
                    :from="85.5"
                    :to="99.9 - randomInRange(10)"
                    :format="theFormat"
                    :duration="0.5"
                    :delay="1"
                    easing="linear"
                  />
                  <p class="count-percent">%</p>
                </div>
                <div class="count-text-percent">出貨率</div>
              </div>

              <div class="line"></div>

              <div
                style="
                  margin-right: 10px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <div style="display: flex; flex-direction: row">
                  <NumberAnimation
                    v-observe-visibility="visibilityChanged"
                    class="count-number"
                    ref="number4"
                    :from="85.5"
                    :to="99.9 - randomInRange(10)"
                    :format="theFormat"
                    :duration="0.5"
                    :delay="1"
                    easing="linear"
                  />
                  <p class="count-percent">%</p>
                </div>
                <div class="count-text-percent" style="padding-right: 0px">
                  訂單評估效率
                </div>
              </div>
            </div>
          </div>
          <p class="statistics">資料統計截至 2024 年 5 月</p>
        </div>

        <img
          style="width: 100%; position: absolute; bottom: -1px"
          src="@/assets/images/desktop/products/Vector.png"
          alt="灰底弧度"
        />
      </div>
    </section>

    <!-- 會員回饋 -->
    <!-- 標題 -->
    <section>
      <div class="gray-square-bg">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 60px;
            padding-bottom: 48px;
          "
        >
          <img src="@/assets/images/desktop/products/quote-left.svg" alt="" />
          <p class="feedback-results" style="">會員回饋</p>
          <img src="@/assets/images/desktop/products/quote-right.svg" alt="" />
        </div>

        <!-- 輪播 -->
        <div class="omg-carousel" style="padding-bottom: 124px">
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
            class="carousel-one"
          >
            <!-- 第一 -->
            <b-carousel-slide class="custom-carousel-slide">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="@/assets/images/desktop/products/8.svg"
                  alt=""
                  style="padding-bottom: 2rem"
                />

                <div style="margin-left: 2rem">
                  <div style="display: flex; justify-content: space-between">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        margin-top: 1rem;
                      "
                    >
                      <p class="photo-text-name">盧小姐</p>
                      <p class="photo-text-position">上班族</p>
                    </div>
                    <img
                      src="@/assets/images/desktop/products/red.svg"
                      alt=""
                      style="padding-bottom: 1rem"
                    />
                  </div>

                  <p class="explain">
                    服務親切、有耐心，詢問問題都會仔細回覆，代購的商品若有問題，也都會協助處理，很推薦喔❤️
                  </p>
                  <p class="date">2022.07.28</p>
                </div>
              </div>
            </b-carousel-slide>

            <!-- 第二 -->
            <b-carousel-slide class="custom-carousel-slide">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="@/assets/images/desktop/products/7.svg"
                  alt=""
                  style="padding-bottom: 2rem"
                />

                <div style="margin-left: 2rem">
                  <div style="display: flex; justify-content: space-between">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        margin-top: 1rem;
                      "
                    >
                      <p class="photo-text-name">林小姐</p>
                      <p class="photo-text-position">插畫家</p>
                    </div>
                    <img
                      src="@/assets/images/desktop/products/red.svg"
                      alt=""
                      style="padding-bottom: 1rem"
                    />
                  </div>

                  <p class="explain">
                    服務好！速度快 價格合理, 專注於批貨平台 👍
                  </p>
                  <p class="date">2022.07.28</p>
                </div>
              </div>
            </b-carousel-slide>

            <!-- 第三 -->
            <b-carousel-slide
              class="custom-carousel-slide"
              style="padding-bottom: 2rem"
            >
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img src="@/assets/images/desktop/products/1.svg" alt="" />
                <div style="margin-left: 2rem">
                  <div style="display: flex; justify-content: space-between">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        margin-top: 2rem;
                      "
                    >
                      <p class="photo-text-name">王先生</p>
                      <p class="photo-text-position">學生</p>
                    </div>
                    <img
                      src="@/assets/images/desktop/products/red.svg"
                      alt=""
                    />
                  </div>

                  <p class="explain">
                    之前東西卡在海關嚇死 還好客服人員有幫忙解決 很感謝
                  </p>
                  <p class="date">2023.01.13</p>
                </div>
              </div>
            </b-carousel-slide>

            <!-- 第四 -->
            <b-carousel-slide
              class="custom-carousel-slide"
              style="padding-bottom: 2rem"
            >
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="@/assets/images/desktop/products/11.svg"
                  alt=""
                  style="padding-bottom: 2rem"
                />
                <div style="margin-left: 2rem">
                  <div style="display: flex; justify-content: space-between">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        margin-top: 2rem;
                      "
                    >
                      <p class="photo-text-name">謝先生</p>
                      <p class="photo-text-position">批發業</p>
                    </div>
                    <img
                      src="@/assets/images/desktop/products/golden.svg"
                      alt=""
                      style="padding-bottom: 1rem"
                    />
                  </div>

                  <p class="explain">
                    代購經驗都很好,店家也很照顧我們,非常感謝!
                  </p>
                  <p class="date">2022.12.10</p>
                </div>
              </div>
            </b-carousel-slide>

            <!-- 第五 -->
            <b-carousel-slide class="custom-carousel-slide">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img src="@/assets/images/desktop/products/5.svg" alt="" />
                <div style="margin-left: 2rem">
                  <div style="display: flex; justify-content: space-between">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        margin-top: 2rem;
                      "
                    >
                      <p class="photo-text-name">張先生</p>
                      <p class="photo-text-position">上班族</p>
                    </div>

                    <img
                      src="@/assets/images/desktop/products/white.svg"
                      alt=""
                    />
                  </div>

                  <p class="explain">
                    有效溝通，且都會密切跟消費者與廠商聯繫！讓人十分安心
                  </p>
                  <p class="date">2021.05.16</p>
                </div>
              </div>
            </b-carousel-slide>

            <!-- 第六 -->
            <b-carousel-slide class="custom-carousel-slide">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img src="@/assets/images/desktop/products/2.svg" alt="" />
                <div style="margin-left: 2rem">
                  <div style="display: flex; justify-content: space-between">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        margin-top: 2rem;
                      "
                    >
                      <p class="photo-text-name">陳先生</p>
                      <p class="photo-text-position">批發業</p>
                    </div>

                    <img
                      src="@/assets/images/desktop/products/golden.svg"
                      alt=""
                    />
                  </div>

                  <p class="explain">
                    我妹妹介紹的!滿好用的!回覆也很快!買到變常客哈!!
                  </p>
                  <p class="date">2023.01.13</p>
                </div>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    console.log(this.$route);
  },
  methods: {
    theFormat(value) {
      return value.toFixed(1);
    },

    randomInRange(range) {
      return Math.random() * range;
    },

    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        this.$refs.number1.restart();
        this.$refs.number2.restart();
        this.$refs.number3.restart();
        this.$refs.number4.restart();
      }
    },
  },
};
</script>

<style>
/* 標題 */
.blue-background {
  background: #5974ff;
  width: 100%;
  top: -5vw;
}

.service-results {
  font-weight: 600;
  font-size: clamp(32px, 2.63vw, 38px);
  color: #ffffff;
  white-space: nowrap;
  margin: 0 23px;
}

/* 數字自己跑 */
.count-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}

.count-window {
  width: 78.72vw;
  max-width: 1120px;
  height: 230px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  justify-content: center;
}

.line {
  border-right: 1px solid #aabac6;
  height: 130px;
}

.count-number,
.count-percent {
  font-weight: 600;
  font-size: clamp(48px, 3.33vw, 48px);
  line-height: 60px;
  color: #3c5bff;
}

.count-text {
  margin-top: 8px;
  font-size: 28px;
  color: #868e96;
}

.count-text-percent {
  margin-top: -9px;
  font-size: 28px;
  color: #868e96;
}

.statistics {
  font-size: 18px;
  color: #f8f9fa;
  margin-top: 40px;
  margin-bottom: 140px;
}

/* 會員回饋 */
.gray-square-bg {
  background-color: #f1f3fa;
}

#carousel-1 {
  background: none !important;
}

.feedback-results {
  font-weight: 600;
  font-size: clamp(32px, 2.63vw, 38px);
  color: #343a40;
  white-space: nowrap;
  margin: 0 23px;
}

.partner-title {
  font-size: 38px;
  color: #343a40;
  font-weight: 600;
  text-align: center;
}

.carousel-one .custom-carousel-slide {
  height: 258px;
  background: #fff !important;
  border-radius: 30px;
}

.photo-text {
  display: flex;
  max-width: 4rem;
}

.photo-text-name {
  font-size: 22px;
  color: #212529;
  font-weight: 600;
  text-align: left;
  line-height: 0 !important;
}

.photo-text-position {
  font-size: clamp(14px, 1.11vw, 16px);
  color: #868e96;
  text-align: left;
}

.explain {
  font-size: 16px;
  color: #343a40;
  text-align: left;
}

.date {
  font-size: clamp(14px, 1.11vw, 16px);
  color: #868e96;
  text-align: right;
  line-height: 0 !important;
}

.carousel-one .carousel-indicators .active {
  background-color: #5974ff !important;
}

.carousel-one .carousel-indicators li {
  background-color: #b1bdff !important;
}

.carousel-one .carousel-control-next-icon,
.carousel-one .carousel-control-prev-icon {
  background-color: #5974ff !important;
  background-size: 50% 50% !important;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}

.carousel-one {
  width: 720px;
  margin: 0 auto;
}
</style>
