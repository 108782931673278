<template>
  <div>
    <section>
      <div style="display: flex; flex-direction: column; align-items: center">
        <p class="do-you-want-buy-text">想在1688買東西，<br />卻困難重重嗎？</p>
        <img class="" src="@/assets/images/desktop/advantage/Group 3860.svg" />
      </div>

      <div style="position: relative">
        <div>
          <img
            class="back-blue"
            src="@/assets/images/desktop/advantage/Group 3861.svg"
          />
          <p class="blue-text">
            這些煩惱<br />
            讓1688專業代買<span
              style="color: #99f9f9; padding-left: 5px; padding-right: 5px"
              >CHATxBUY</span
            >通通幫你搞定！
          </p>
        </div>
        <img class="plant" src="@/assets/images/desktop/advantage/plant.svg" />
      </div>
    </section>

    <section>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div style="display: flex; padding-top: 90px">
          <img src="@/assets/images/desktop/advantage/right.svg" alt="" />
          <p class="feature-content">
            CHATxBUY <span style="color: #3c5bff">5</span> 大特色
          </p>
          <img src="@/assets/images/desktop/advantage/left.svg" alt="" />
        </div>
        <p class="sidehead">化繁為簡，為您提供 1688 專業一條龍代買服務</p>
      </div>

      <div
        style="
          padding-top: 60px;
          padding-bottom: 60px;
          display: flex;
          justify-content: center;
        "
      >
        <div class="step-5-box">
          <img src="@/assets/images/desktop/advantage/Group 3862.svg" />
          <div style="display: flex; flex-direction: column">
            <p class="p-content">聊天機器人下單</p>
            <p class="p-content2">免下載APP，手機一鍵搞定<br />隨時可下單</p>
          </div>
        </div>
        <div class="step-5-box">
          <img src="@/assets/images/desktop/advantage/Group 3863.svg" />
          <div style="display: flex; flex-direction: column">
            <p class="p-content">專業 1688 代購經驗</p>
            <p class="p-content2">
              台灣團隊，多年代購經驗<br />細心掌握每個流程
            </p>
          </div>
        </div>

        <div class="step-5-box">
          <img src="@/assets/images/desktop/advantage/Group 3864.svg" />
          <div style="display: flex; flex-direction: column">
            <p class="p-content">費用明確清楚</p>
            <p class="p-content2">收費結構透明<br />收到報價後，滿意再付款</p>
          </div>
        </div>

        <div class="step-5-box">
          <img src="@/assets/images/desktop/advantage/Group 3865.svg" />
          <div style="display: flex; flex-direction: column">
            <p class="p-content">訂單進度一目了然</p>
            <p class="p-content2">全程跟進訂單進度<br />服務時間內，即時溝通</p>
          </div>
        </div>
        <div class="step-5-box">
          <img src="@/assets/images/desktop/advantage/Group 3866.svg" />
          <div style="display: flex; flex-direction: column">
            <p class="p-content">協助售後溝通</p>
            <p class="p-content2">到貨後如有問題<br />協助您與 1688 廠商溝通</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log(this.$route);
    document.dispatchEvent(new Event('render-event')); //prerender
  },
};
</script>

<style scope>
.back-blue {
  width: 100%;
  height: auto;
  position: relative;
}

.plant {
  height: auto;
  position: absolute;
  left: 9%;
  transform: translateY(-100%);
}

.do-you-want-buy-text {
  text-align: center;
  font-size: 32px;
  margin-top: 121px;
}

.blue-text {
  color: #ffffff;
  font-size: clamp(26px, 2.22vw, 32px);
  height: auto;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
  text-align: center;
}

.feature-content {
  font-weight: 800;
  font-size: 38px;
  transform: scaleY(1.05);
  color: #343a40;
  margin: 0;
  padding-left: 22px;
  padding-right: 22px;
}

.sidehead {
  font-weight: 500;
  font-size: 24px;
  color: #495057;
  padding-top: 30px;
  margin: 0;
}

.p-content {
  color: #5974ff;
  font-weight: bold;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 14px;
  margin: 0;
  text-align: center;
}

.p-content2 {
  color: #495057;
  font-size: 15px;
  text-align: center;
}

.step-5-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 25px;
  margin-right: 25px;
}

.step-5-box:first-child {
  margin-left: 0;
}

.step-5-box:last-child {
  margin-right: 0;
}
</style>
