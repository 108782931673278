<script setup>
import GoChatBot from "@/components/GoChatBot.vue";
</script>

<template>
  <div>
    <section style="display: flex; flex-direction: column; align-items: center">
      <p class="Finally-content">
        無論何時何地，<br />
        我們都是您<span style="color: #3c5bff">值得信賴</span>的1688代買夥伴
      </p>

      <img
        class="Finally-picture"
        src="@/assets/images/desktop/recommend/Group 3875.svg"
        alt=""
      />

      <GoChatBot class="Finally-button-pointer" />
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    goChatbot() {
      gtag("event", "Index_click", { action: "goChatbot", page: "hero" });
      if (localStorage.utm_ref != null) {
        window.open("https://m.me/chatXbuy?ref=" + localStorage.utm_ref);
      } else {
        window.open("https://m.me/chatXbuy");
      }
    },
  },
  mounted() {
    console.log(this.$route);
    document.dispatchEvent(new Event('render-event')); //prerender
  },
};
</script>

<style scope>
.Finally-content {
  font-weight: 500;
  font-size: 32px;
  color: #343a40;
  text-align: center;
  margin-top: 80px;
  line-height: 60px;
}

.Finally-picture {
  width: 100%;
}

.Finally-button-pointer {
  width: 220px;
  margin-top: 60px;
  margin-bottom: 80px;
  cursor: pointer;
}
</style>
